<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="anasalemethod">
        <div class="box">
            <!-- <div class="tit">
                <el-switch
                v-model="value1"
                @click.native='dianji_kaiguan'
                active-color="#6ABFA7">
                </el-switch>
                <p>默认启用</p>
                <span>此开关是针对全体员工是否显示销售模块，请慎重选择。</span>
            </div> -->
            <div class="box_wai">
                <div class="box">
                    <p class="p1">合同预测方法选择(单选)</p>
                    <p :class="hetong_fangfa==2?'p2':'p3'" @click="dianji_hetong_dingyi">定义法</p>
                    <p :class="hetong_fangfa==1?'p2':'p3'" @click="dianji_hetong_jieduan">阶段法</p>
                </div>
                <transition name="fade">
                    <div class="bottoma" v-if="hetong_fangfa==1">
                        <div>
                            <p class="p4">初步了解阶段</p>
                            <p class="p5">{{hetong_jieduan_shuzi[0].stage_rate*100}}%</p>
                        </div>
                        <div>
                            <p class="p4">需求确认阶段</p>
                            <p class="p5">{{hetong_jieduan_shuzi[1].stage_rate*100}}%</p>
                        </div>
                        <div>
                            <p class="p4">报价及方案展示阶段</p>
                            <p class="p5">{{hetong_jieduan_shuzi[2].stage_rate*100}}%</p>
                        </div>
                        <div>
                            <p class="p4">商务谈判阶段</p>
                            <p class="p5">{{hetong_jieduan_shuzi[3].stage_rate*100}}%</p>
                        </div>
                        <div>
                            <p class="p4">赢单</p>
                            <p class="p5">100%</p>
                        </div>
                        <div>
                            <p class="p4">输单</p>
                            <p class="p5">0</p>
                        </div>
                        <div>
                            <p class="p4">编辑</p>
                            <p class="p5" style="cursor:pointer;color:#F96C6C;" @click="dianji_bianji">编辑</p>
                        </div>
                    </div>
                </transition>
            </div>
            <div class="box_wai">
                <div class="box">
                    <p class="p1">发票预测方法选择(单选)</p>
                    <p :class="fapiao_fangfa==2?'p2':'p3'" @click="dianji_fapiao_dingyi">定义法</p>
                    <p :class="fapiao_fangfa==1?'p2':'p3'" @click="dianji_fapiao_zhouqi">周期法</p>
                </div>
                <transition name="fade">
                    <div class="bottomb" v-if="fapiao_fangfa==1">
                        <p>合同签订后预计平均</p>
                        <input type="number" v-model="zong_list[1].term" @keyup="keyu1" placeholder="天数" onkeyup="this.value=this.value.replace(/\D/g,'')" onafterpaste="this.value=this.value.replace(/\D/g,'')">
                        <p>天后开具发票</p>
                        <p class="queding" v-if="fapiao_xiugai" @click="fapiao_xiugai_sure">确定</p>
                    </div>
                </transition>
            </div>
            <div class="box_wai" style="padding-bottom:0.4rem;">
                <div class="box">
                    <p class="p1">回款预测方法选择(单选)</p>
                    <p :class="huikuan_fangfa==2?'p2':'p3'" @click="dianji_huikuan_dingyi">定义法</p>
                    <p :class="huikuan_fangfa==1?'p2':'p3'" @click="dianji_huikuan_zhouqi">周期法</p>
                </div>
                <transition name="fade">
                    <div class="bottomb" v-if="huikuan_fangfa==1">
                        <p>发票开具后预计平均</p>
                        <input type="number" v-model="zong_list[2].term" @keyup="keyu2" placeholder="天数" onkeyup="this.value=this.value.replace(/\D/g,'')" onafterpaste="this.value=this.value.replace(/\D/g,'')">
                        <p>天回款到账</p>
                        <p class="queding" v-if="huikuan_xiugai" @click="huikuan_xiugai_sure">确定</p>
                    </div>
                </transition>
            </div>
        </div>
        <el-drawer
            :visible.sync="drawer"
            :modal='false'
            :show-close='false'
            title="合同预测方法阶段法"
            size="620">
            <div style="color:#888888;font-size:0.18rem;height:0.42rem;width:94%;background:#E7E8EB;margin:0 auto;line-height:0.42rem;padding:0 0.12rem;min-width:3.88rem;">阶段信息</div>
            <ul class="liebiao">
                <li class="dd6">
                    <span>*</span>初步了解阶段
                </li>
                <li class="dd7"><input type="number" :min="1" :max="100" @keyup='key1' onkeyup="this.value=this.value.replace(/\D/g,'')" onafterpaste="this.value=this.value.replace(/\D/g,'')" placeholder='1-99' v-model="value2">&nbsp;%</li>
            </ul>
            <ul class="liebiao">
                <li class="dd6">
                    <span>*</span>需求确认阶段
                </li>
                <li class="dd7"><input type="number" :min="1" :max="100" @keyup='key2'  onkeyup="this.value=this.value.replace(/\D/g,'')" onafterpaste="this.value=this.value.replace(/\D/g,'')" placeholder='1-99' v-model="value3">&nbsp;%</li>
            </ul>
            <ul class="liebiao">
                <li class="dd6">
                    <span>*</span>报价及方案展示阶段
                </li>
                <li class="dd7"><input type="number" :min="1" :max="100" @keyup='key3'  onkeyup="this.value=this.value.replace(/\D/g,'')" onafterpaste="this.value=this.value.replace(/\D/g,'')" placeholder='1-99' v-model="value4">&nbsp;%</li>
            </ul>
            <ul class="liebiao">
                <li class="dd6">
                    <span>*</span>商务谈判阶段
                </li>
                <li class="dd7"><input type="number" :min="1" :max="100" @keyup='key4'  onkeyup="this.value=this.value.replace(/\D/g,'')" onafterpaste="this.value=this.value.replace(/\D/g,'')" placeholder='1-99' v-model="value5">&nbsp;%</li>
            </ul>
            <ul class="liebiao">
                <li class="dd6">
                    <span>*</span>赢单
                </li>
                <li class="dd7"><p>100%</p></li>
            </ul>
            <ul class="liebiao">
                <li class="dd6">
                    <span>*</span>输单
                </li>
                <li class="dd7"><p>0</p></li>
            </ul>
            <div class="xuan_foot">
                <div style="color:#fff;background:#F96C6C" @click="box_queding">确定</div>
                <div style="color:#888888;border:0.01rem solid #888888" @click="drawer = false">取消</div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import { query_method_set, update_con_stage_rate_batch, update_method_set, query_module_status, update_module_status } from '../../api/api.js'
export default {
  name: 'anasalemethod',
  data () {
    return {
      ent_id: this.$ent_id(),
      inde: '0',
      list: ['显示', '方法或方案'],
      // value1: '',
      hetong_fangfa: '',
      fapiao_fangfa: '',
      huikuan_fangfa: '',
      drawer: false,
      value2: '',
      value3: '',
      value4: '',
      value5: '',
      hetong_jieduan_shuzi: [],
      id: '',
      zong_list: [],
      module_id: '',
      est_method: '',
      huikuan_xiugai: false,
      fapiao_xiugai: false,
      jichuxinxi: this.$jichuxinxi()
    }
  },
  mounted () {
  },
  created () {
    this.jichu()
    // this.query_module_status_aa()
  },
  watch: {
        
  },
  methods: {
    // 获取基础信息
    jichu () {
      query_method_set({
        data: {
          ent_id: this.ent_id,
          user_id: this.jichuxinxi.user_id,
          module_id: '2',
          function_id: null
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10129) {
          console.log('销售接口', JSON.parse(res.data.body.data))
          const data = JSON.parse(res.data.body.data)
          console.log(JSON.parse(res.data.body.con_stage))
          const date = JSON.parse(res.data.body.con_stage)
          const arr = [
            {
              est_method: '2',
              function_id: '1',
              module_id: '2'
            },
            {
              est_method: '2',
              function_id: '2',
              module_id: '2'
            },
            {
              est_method: '2',
              function_id: '3',
              module_id: '2'
            }
          ]
          for (let i = 0; i < arr.length; i++) {
            for (let a = 0; a < data.length; a++) {
              if (arr[i].function_id == data[a].function_id) {
                arr[i] = data[a]
              }
            }
          }
          this.zong_list = arr
          this.hetong_fangfa = arr[0].est_method
          this.fapiao_fangfa = arr[1].est_method
          this.huikuan_fangfa = arr[2].est_method
          if (date.length != 0) {
            this.hetong_jieduan_shuzi = date
          } else {
            this.hetong_jieduan_shuzi = [
              {
                stage_id: '1',
                stage_rate: ''
              },
              {
                stage_id: '2',
                stage_rate: ''
              },
              {
                stage_id: '3',
                stage_rate: ''
              },
              {
                stage_id: '4',
                stage_rate: ''
              }
            ]
          }
        } else if (res.data.code == 10130) {
        }
      })
    },
    dian_list (index) {
      this.inde = index
    },
    // 点击合同阶段法
    dianji_hetong_jieduan () {
      this.hetong_fangfa = '1'
      this.est_method = '1'
      this.id = this.zong_list[0].function_id
      this.module_id = this.zong_list[0].module_id
      this.update_method_set_aa()
    },
    // 点击合同定义法
    dianji_hetong_dingyi () {
      this.hetong_fangfa = '2'
      this.est_method = '2'
      this.id = this.zong_list[0].function_id
      this.module_id = this.zong_list[0].module_id
      this.update_method_set_aa()
    },
    // 点击发票阶段法
    dianji_fapiao_zhouqi () {
      this.fapiao_fangfa = '1'
      this.est_method = '1'
      this.id = this.zong_list[1].function_id
      this.module_id = this.zong_list[1].module_id
      this.update_method_set_aa()
    },
    // 点击发票定义法
    dianji_fapiao_dingyi () {
      this.fapiao_fangfa = '2'
      this.est_method = '2'
      this.id = this.zong_list[1].function_id
      this.module_id = this.zong_list[1].module_id
      this.update_method_set_aa()
    },
    // 点击回款阶段法
    dianji_huikuan_zhouqi () {
      this.huikuan_fangfa = '1'
      this.est_method = '1'
      this.id = this.zong_list[2].function_id
      this.module_id = this.zong_list[2].module_id
      this.update_method_set_aa()
    },
    // 点击回款定义法
    dianji_huikuan_dingyi () {
      this.huikuan_fangfa = '2'
      this.est_method = '2'
      this.id = this.zong_list[2].function_id
      this.module_id = this.zong_list[2].module_id
      this.update_method_set_aa()
    },
    // 修改方法接口
    update_method_set_aa () {
      update_method_set({
        data: {
          function_id: this.id + '',
          module_id: this.module_id,
          est_method: this.est_method,
          ent_id: this.ent_id,
          user_id: this.jichuxinxi.user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10127) {
          this.$message({
            message: '切换成功',
            type: 'success'
          }) 
        } else if (res.data.code == 10128) {
        }
      })
    },
    // 查询模块状态
    // query_module_status_aa(){
    //     query_module_status({data:{
    //         ent_id:this.ent_id,
    //         user_id:this.jichuxinxi.user_id,
    //         module_id:'2'
    //     }}).then(res=>{
    //         console.log(11111111,res)
    //         if(res.data.code==10189){
    //             if(JSON.parse(res.data.body.data)==1){
    //                 this.value1=true
    //             }else if(JSON.parse(res.data.body.data)==0){
    //                 this.value1=false
    //             }
    //         }else if(res.data.code==10190){
    //         }
    //     })
    // },
    // 确定
    box_queding () {
      if (this.value2 != 0 && this.value3 != 0 && this.value4 != 0 && this.value5 != 0) {
        const a = this.value2 / 100 + ''
        const b = this.value3 / 100 + ''
        const c = this.value4 / 100 + ''
        const d = this.value5 / 100 + ''
        console.log(a)
        console.log(b)
        console.log(c)
        console.log(d)
        update_con_stage_rate_batch({
          data: {
            ent_id: this.ent_id,
            user_id: this.jichuxinxi.user_id,
            list: [
              {
                stage_id: '1',
                stage_rate: a
              },
              {
                stage_id: '2',
                stage_rate: b
              },
              {
                stage_id: '3',
                stage_rate: c
              },
              {
                stage_id: '4',
                stage_rate: d
              }
            ]
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10187) {
            this.$message({
              message: '添加成功',
              type: 'success'
            }) 
            this.jichu()
            this.drawer = false
          } else if (res.data.code == 10188) {
            this.$message({
              message: '添加失败',
              type: 'error'
            }) 
          }
        })
      } else {
        this.$message({
          message: '请填写完整的信息',
          type: 'warning'
        }) 
      }
    },
    // 点击编辑
    dianji_bianji () {
      this.drawer = true
      if (this.hetong_jieduan_shuzi.length == 4) {
        this.value2 = this.hetong_jieduan_shuzi[0].stage_rate * 100
        this.value3 = this.hetong_jieduan_shuzi[1].stage_rate * 100
        this.value4 = this.hetong_jieduan_shuzi[2].stage_rate * 100
        this.value5 = this.hetong_jieduan_shuzi[3].stage_rate * 100
      }
    },
    key1 () {
      if (this.value2 == 0) {
        this.value2 = 1
      } else if (this.value2 > 100) {
        this.value2 = 100
      }
    },
    key2 () {
      if (this.value3 == 0) {
        this.value3 = 1
      } else if (this.value3 > 100) {
        this.value3 = 100
      }
    },
    key3 () {
      if (this.value4 == 0) {
        this.value4 = 1
      } else if (this.value4 > 100) {
        this.value4 = 100
      }
    },
    key4 () {
      if (this.value5 == 0) {
        this.value5 = 1
      } else if (this.value5 > 100) {
        this.value5 = 100
      }
    },
    keyu1 () {
      this.fapiao_xiugai = true
    },
    keyu2 () {
      this.huikuan_xiugai = true
    },
    // 发票修改的确定
    fapiao_xiugai_sure () {
      const tian = this.zong_list[1].term + ''
      console.log(tian)
      update_method_set({
        data: {
          id: '2',
          module_id: '2',
          function_id: '2',
          est_method: '1',
          ent_id: this.ent_id,
          user_id: this.jichuxinxi.user_id,
          term: tian
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10127) {
          this.$message({
            message: '成功',
            type: 'success'
          }) 
          this.fapiao_xiugai = false
        } else if (res.data.code == 10128) {
          this.$message({
            message: '失败',
            type: 'error'
          }) 
        }
      })
    },
    // 回款修改的确定
    huikuan_xiugai_sure () {
      const tian = this.zong_list[2].term + ''
      console.log(tian)
      update_method_set({
        data: {
          id: '3',
          module_id: '2',
          function_id: '3',
          est_method: '1',
          ent_id: this.ent_id,
          user_id: this.jichuxinxi.user_id,
          term: tian
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10127) {
          this.$message({
            message: '成功',
            type: 'success'
          }) 
          this.fapiao_xiugai = false
        } else if (res.data.code == 10128) {
          this.$message({
            message: '失败',
            type: 'error'
          }) 
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
 @import './quanxian.scss';
</style>
